import * as React from "react";
import { getSpotifyToken } from "../actions/spotify";

export const useSpotifyToken = () => {
  const [response, setToken] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [, setError] = React.useState(null);

  React.useEffect(() => {
    setLoading(true);
    getSpotifyToken()
      .then(data => {
        setLoading(false);
        setToken(data);
      })
      .catch(err => {
        setError(err);
      });
  }, []);

  return [response, loading];
};
