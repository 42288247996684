import React from "react";
import ErrorBoundary from "../ErrorBoundary";
import { userContext } from "../../utilities/user-context";
import { db } from "../../secondary/firebase";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useWindowSize } from "react-use";
import { Spin, Drawer, Divider, Row, Col } from "antd";
import FstvlDivider from "../FstvlDivider";
import {
  FestivalDetailLineup,
  FestivalDetailHeader,
  FestivalDetailArtistsBar,
  FestivalDetailDescription,
  FestivalDetailMap
} from "./index";

const FestivalDetail = props => {
  const { id } = props;
  const { user } = React.useContext(userContext);
  const [favedFestivalList, loadingFaved] = useCollection(
    db.collection("favedFestivals")
  );
  const [festivalValue, festivalLoading] = useDocument(
    db.collection("festivals").doc(id)
  );
  const [isFaved, setFaved] = React.useState(false);
  const [isDrawerVisible, setDrawerVisibility] = React.useState(false);
  const { width } = useWindowSize();
  const showDrawer = evt => {
    evt.preventDefault();

    setDrawerVisibility(true);
  };

  const onClose = () => {
    setDrawerVisibility(false);
  };

  React.useEffect(() => {
    if (!favedFestivalList) return;

    const foundFaved = favedFestivalList.docs.find(obj => {
      return obj.data().fid === props.id;
    });

    if (foundFaved) setFaved(foundFaved);
  }, [favedFestivalList, user.uid, props]);

  if (festivalLoading || loadingFaved) {
    return (
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          padding: "3rem",
          justifyContent: "center",
          display: "flex"
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  const data = festivalValue.data();
  // const artists = data.artists || [];

  console.log(data);

  return (
    <div>
      <FestivalDetailHeader
        id={id}
        img={[]}
        faved={isFaved}
        location={data.location.city}
        date={data.date}
        name={data.name}
      />
      <div className="bg-white relative">
        <FestivalDetailArtistsBar
          id={id}
          drawer={showDrawer}
          name={data.seriesName}
          artists={data.artists}
        />
        <Row className="mt-24 mb-12">
          <Col xs={{ span: 22, offset: 1 }}>
            <Divider className="my-0" />
          </Col>
        </Row>
        <FestivalDetailDescription id={id} data={data} />
        <FestivalDetailMap
          id={id}
          venue={data.venue}
          location={data.location}
        />
      </div>
      <Drawer
        title={`Lineup for ${data.name}`}
        placement="right"
        destroyOnClose={false}
        closable={true}
        onClose={onClose.bind(this)}
        visible={isDrawerVisible}
        width={width}
        style={{ position: "absolute" }}
      >
        <FestivalDetailLineup id={id} />
      </Drawer>
    </div>
  );
};

export default function FestivalDetailsWithErrorBoundary(props) {
  return (
    <ErrorBoundary>
      <FestivalDetail {...props}></FestivalDetail>
    </ErrorBoundary>
  );
}
