import React from "react";
import { Layout, Divider, Typography } from "antd";

const { Text } = Typography;

const FstvlDivider = props => {
  const { title, size, color } = props;
  const paddingClassName = title ? "py-24" : "";
  let heightClassName;
  let backgroundColorClass;

  if (color) {
    backgroundColorClass = `fstvl-divider-horizontal--${color}`;
  }

  switch (size) {
    case "default":
      heightClassName = "h-2";
      break;
    case "large":
      heightClassName = "h-4";
      break;
    default:
      heightClassName = "h-2";
      break;
  }

  return (
    <div className={`relative w-full ${paddingClassName} ${heightClassName}`}>
      {props.title ? (
        <div
          className={`w-full h-full absolute flex justify-center items-center top-0 bg-none`}
        >
          <div
            className={`fstvl-divider-horizontal ${backgroundColorClass} absolute ${heightClassName} w-full`}
          ></div>
          <Text className="px-10 bg-white inline-block relative">{title}</Text>
        </div>
      ) : (
        <div
          className={`w-full h-full absolute flex justify-center items-center top-0 bg-none`}
        >
          <div
            className={`fstvl-divider-horizontal ${backgroundColorClass} absolute ${heightClassName} w-full`}
          ></div>
        </div>
      )}
    </div>
  );
};

export default FstvlDivider;
