import React from "react";
import { Link } from "wouter";
import { Row, Col, Layout, Menu, Dropdown, Button } from "antd";
import { signOut } from "../../auth";
import { UserOutlined } from "@ant-design/icons";
import logoWhite from "../../svg/fstvl-white.svg";

const { Header } = Layout;

const menuDropdown = (
  <Menu>
    <Menu.Item>
      <Button type="link" onClick={signOut}>
        Sign Out
      </Button>
    </Menu.Item>
  </Menu>
);

const AppHeaderBar = () => {
  return (
    <Header className="sticky mast-head">
      <Row gutter={16}>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
          <div className="flex flex-row w-full justify-between items-center my-12">
            <Link
              css={{
                textDecoration: "none"
              }}
              to="/"
            >
              <img
                style={{
                  width: "135px",
                  height: "40px"
                }}
                src={logoWhite}
                aria-hidden
              />
            </Link>

            <Dropdown overlay={menuDropdown}>
              <Button
                icon={
                  <UserOutlined
                    style={{
                      fill: "#fff",
                      color: "#fff"
                    }}
                  />
                }
                type="primary"
                shape="circle"
                ghost
                style={{
                  borderColor: "#fff",
                  borderWidth: "2px"
                }}
                className="ant-dropdown-link text-white fill-current"
                onClick={e => e.preventDefault()}
              ></Button>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </Header>
  );
};

export default AppHeaderBar;
