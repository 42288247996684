import React, { useState, useContext, useEffect } from "react";
import { userContext } from "../utilities/user-context";
import { useCollection } from "react-firebase-hooks/firestore";
import { db } from "../secondary/firebase";
import EmptyFestivalCard from "./EmptyFestivalCard";
import { Spin, Row, Col, Typography } from "antd";
import FavedFestivals from "./FavedFestivals";
import AllFestivalCard from "./AllFestivals";

const Landing = () => {
  const { Title } = Typography;
  const { user } = useContext(userContext);
  const [festivals, loading] = useCollection(db.collection("festivals"));
  const [faved, loadingFaved] = useCollection(db.collection("favedFestivals"));
  const [userFavedFests, setUserFavedFests] = useState([]);

  useEffect(() => {
    if (!faved) return;

    const userFests = faved.docs.filter(obj => {
      if (obj.data().uid == user.uid) {
        return obj;
      }
    });

    return setUserFavedFests(userFests);
  }, [faved, user.uid]);

  if (loading || loadingFaved) {
    return (
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          padding: "3rem",
          justifyContent: "center",
          display: "flex"
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="container mx-auto grid sm:grid-cols-2 md:grid-cols-2">
      <Row gutter={16}>
        {!userFavedFests || userFavedFests.length ? (
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
            <FavedFestivals list={userFavedFests}></FavedFestivals>
          </Col>
        ) : (
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
            <div className="h-2/3-screen flex flex-col justify-center items-center text-center">
              <Title
                className="display-1 text-blue-light flush-bottom"
                level={1}
              >
                Oh no!
              </Title>
              <Title className="h4 flush text-center" level={4}>
                You do not currently have any Festivals or Artists saved.{" "}
                <span role="img" aria-label="sad emoji">
                  😭
                </span>
              </Title>
            </div>
          </Col>
        )}
      </Row>
      <Row gutter={16} type="flex">
        {!festivals || !festivals.docs.length ? (
          <EmptyFestivalCard />
        ) : (
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
            <AllFestivalCard
              favedFestivals={userFavedFests}
              festivals={festivals}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Landing;
