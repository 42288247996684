import React, { useState } from "react";
import {
  Form,
  Button,
  Input,
  Layout,
  Typography,
  Row,
  Alert,
  Col,
  Divider
} from "antd";
// import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import queryString from "query-string";
import {
  loginWithGoogle,
  loginWithEmail,
  createUserWithEmail
} from "../../auth";
import { Redirect } from "wouter";
import GoogleButton from "react-google-button";
import FstvlDivider from "./FstvlDivider";
import logoWhite from "../../svg/fstvl-all-white.svg";

// const { Header, Footer, Sider, Content } = Layout;
const { Title, Text, Paragraph } = Typography;

const Login = ({ id }) => {
  const qs = queryString.parse(location.search);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [form, setForm] = React.useState({ email: "", password: "" });
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);

  const [isRegistering, setIsRegistering] = useState(
    typeof qs.register === "string"
  );

  if (redirectToReferrer) {
    return <Redirect from="" to={from.pathname} noThrow />;
  }

  function loginEmail() {
    const { password, email } = form;
    const fn = isRegistering ? createUserWithEmail : loginWithEmail;

    return async () => {
      try {
        setError("");
        setLoading(true);
        await fn(email, password);
        setRedirectToReferrer(true);
      } catch (err) {
        setLoading(false);
        setError(err.message || "Please try again.");
      }
    };
  }

  function login(fn) {
    return async () => {
      try {
        setError("");
        setLoading(true);
        await fn();
        // setRedirectToReferrer(true);
      } catch (err) {
        setLoading(false);
        setError(err.message || "Please try again.");
      }
    };
  }

  return (
    <Layout>
      <div className="px-16 login-panel h-half-screen bg-cover bg-no-repeat">
        <img
          style={{
            width: "135px",
            height: "40px"
          }}
          className="relative mx-auto top-1/3"
          alt="logo"
          src={logoWhite}
          aria-hidden
        />
      </div>
      <Row gutter={16}>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
          <Layout className="mx-auto px-24 pt-24 pb-100 layour-inner text-center -m-100 rounded overflow-hidden">
            {error && (
              <Alert
                className="text-left"
                message="An error has occurred."
                description={error}
                type="error"
                showIcon
              />
            )}
            <Title level={2}>Let’s get started!</Title>
            <Text className="text-sm">
              {isRegistering ? "Create an account" : "Log In"} to schedule your
              next festival so you don’t miss your favorite artist’s next set!
            </Text>
            <Divider />

            <Form
              layout="horizontal"
              onFinish={loginEmail()}
              initialValues={{ remember: true }}
            >
              <Form.Item
                className="text-left"
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" }
                ]}
              >
                <Input
                  onChange={e => {
                    setForm({ ...form, email: e.currentTarget.value });
                  }}
                  placeholder="pasquale@email.com"
                  size={"large"}
                />
              </Form.Item>
              <Form.Item
                className="text-left"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" }
                ]}
              >
                <Input.Password
                  onChange={e => {
                    setForm({ ...form, password: e.currentTarget.value });
                  }}
                  size={"large"}
                  placeholder="•••••••"
                />
              </Form.Item>
              <Form.Item
                style={{
                  margin: "0"
                }}
              >
                <Button
                  className="mt-12 button-wide"
                  style={{
                    height: "50px",
                    width: "240px"
                  }}
                  size="large"
                  type="primary"
                  htmlType="submit"
                >
                  {isRegistering ? "Create Account" : "Log In"}
                </Button>

                {isRegistering ? (
                  <Paragraph>
                    Already have an account?{" "}
                    <Button
                      type="link"
                      onClick={e => setIsRegistering(false)}
                      style={{ padding: 0 }}
                    >
                      Sign In
                    </Button>
                  </Paragraph>
                ) : (
                  <Paragraph>
                    Dont have an account?{" "}
                    <Button
                      onClick={e => setIsRegistering(true)}
                      type="link"
                      style={{ padding: 0 }}
                    >
                      Sign Up
                    </Button>
                  </Paragraph>
                )}
              </Form.Item>
            </Form>
            <FstvlDivider title="or" className="h-4" />
            <GoogleButton
              label={
                isRegistering ? "Register using Google" : "Log In with Google"
              }
              className="mx-auto mb-24 overflow-hidden"
              onClick={login(loginWithGoogle)}
            />
            <Paragraph className="text-xs w-3/4 mx-auto">
              By creating Account you agree to FSTVL’s{" "}
              <a href="#">Terms & Conditions</a> and{" "}
              <a href="#">Privacy Policy</a>.
            </Paragraph>
          </Layout>
        </Col>
      </Row>
    </Layout>
  );
};

export default Login;
