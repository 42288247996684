import React from "react";
import firebase from "firebase/app";
import { Route, Redirect, useRoute } from "wouter";
import { useAuthState } from "react-firebase-hooks/auth";
import { userContext } from "../utilities/user-context";
import { useSpotifyToken } from "../hooks/useSpoitfyToken";
import { spotifyContext } from "../utilities/spotify-context";
import { Spin, Layout } from "antd";
import Login from "../components/Login";
import AppHeaderBar from "../components/HeaderBar";
import FestivalDetail from "../components/FestivalDetail/FestivalDetail";
import FestivalLineup from "../components/FestivalLineup";
import Landing from "../components/Landing";
import { useSession } from "../../auth";

const PrivateRoute = ({ path }) => {
  let [match, params] = useRoute(path);
  const user = useSession();
  let Component;

  const handleFestivalDetailRoute = path => {
    if (path[2] && path[2] === "times") {
      return FestivalLineup;
    }
    return FestivalDetail;
  };

  if (params.rest) {
    const pathArr = params.rest.split("/");
    let properties = {
      id: pathArr[1]
    };
    params = properties;

    switch (pathArr[0]) {
      case "festival":
        Component = handleFestivalDetailRoute(pathArr);
        break;
      case "times":
        Component = FestivalLineup;
        break;
      default:
        break;
    }
  }

  if (!params || !Component) {
    return React.createElement(Redirect, { to: "/" });
  }

  if (!user && params.id) {
    return React.createElement(Redirect, { to: "/" });
  }

  if (!user || !match) {
    return null;
  }

  return React.createElement(Component, { ...params });
};

const App = () => {
  const [user, initializing] = useAuthState(firebase.auth());
  const [spotifyToken, spotifyLoading] = useSpotifyToken();
  const { Content } = Layout;

  if (initializing || spotifyLoading) {
    return (
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          padding: "3rem",
          justifyContent: "center",
          display: "flex"
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <userContext.Provider
      value={{
        user: user,
        initializing: initializing
      }}
    >
      <spotifyContext.Provider
        value={{
          token: spotifyToken
        }}
      >
        {user && <AppHeaderBar />}
        <Content>
          {!user && <Route path="/" component={Login} />}
          {user && <Route path="/" component={Landing} />}
          <PrivateRoute path="/:rest*" />
        </Content>
      </spotifyContext.Provider>
    </userContext.Provider>
  );
};

export default App;
