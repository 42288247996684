import React, { useContext } from "react";
import { Row, Col, Typography, Layout } from "antd";
import FestivalCard from "./FestivalCard/FestivalCard";
import FstvlDivider from "./FstvlDivider";
import { userContext } from "../utilities/user-context";

const { Title } = Typography;

const FavedFestivals = props => {
  const { list } = props;
  const { user } = useContext(userContext);

  return (
    <div>
      <div className="my-24 overflow-hidden">
        <Row gutter={16}>
          <Col span="24">
            <Title className="text-black flush-top" level={4}>
              Your Upcoming Saved Festivals
            </Title>
            <FstvlDivider size="large" />
          </Col>
        </Row>
      </div>

      <Layout>
        {!list || !list.length ? (
          <Title level={3}>Nothing</Title>
        ) : (
          list.map(item => {
            const fest = item.data();

            return (
              <FestivalCard
                userFestCard={true}
                isFaved={true}
                key={item.id}
                fid={item.id}
                uid={user.uid}
                id={fest.fid}
              />
            );
          })
        )}
      </Layout>
    </div>
  );
};

export default FavedFestivals;
