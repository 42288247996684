import React from "react";
import { animated, useSpring, useTransition, config } from "react-spring";

const HeaderHeroImage = ({ url, duration }) => {
  const props = useSpring({
    from: {
      transformOrigin: "bottom",
      transform: "scale(1) translateX(0%)"
    },
    to: {
      transformOrigin: "bottom",
      transform: "scale(2) translateX(20%)"
    },
    config: {
      duration: duration - 200
    }
  });

  return (
    <animated.div
      className="absolute inset-0 h-full w-full bg-cover bg-no-repeat "
      style={{ ...props, backgroundImage: url }}
    />
  );
};

export default HeaderHeroImage;
