import { client_secret, client_id } from "../../app/config/spotify";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { db } from "../secondary/firebase";
import get from "simple-get";
import { encode } from "js-base64";

const base64_enc = encode(`${client_id}:${client_secret}`);

const addTokenToDB = obj => {
  return new Promise(resolve => {
    return db
      .collection("API_Spotify")
      .add({
        ...obj,
        updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
      })
      .then(docRef => {
        resolve(docRef);
      });
  });
};

const removeTokenFromDB = id => {
  return db
    .collection("API_Spotify")
    .doc(id)
    .delete();
};

const generateSpotifyToken = () => {
  const opts = {
    url: "https://accounts.spotify.com/api/token",
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${base64_enc}`
    },
    body: "grant_type=client_credentials"
  };
  const obj = {
    created_at: new Date().getTime()
  };

  return new Promise(resolve => {
    get.concat(opts, function(err, res, data) {
      if (err) throw err;
      const dataObj = JSON.parse(data);
      const resObj = {
        ...obj,
        ...dataObj,
        expires_in: obj.created_at + dataObj.expires_in * 1000
      };

      // console.log("new Expiration current time!", new Date(obj.currTime));
      // console.log("new Expiration time to expires!", dataObj.expires_in);
      // console.log("new Expiration!", new Date(resObj.expires));
      resolve(resObj);
    });
  });
};

export const getSpotifyToken = () => {
  return new Promise(resolve => {
    const time = new Date().getTime();

    db.collection("API_Spotify")
      .limit(1)
      .get()
      .then(snap => {
        if (snap.docs.length) {
          let tokenRef = snap.docs[0];
          let token = snap.docs[0].data();

          // console.log(
          //   `expires: ${new Date(token.expires)}`,
          //   `curr: ${new Date(currTime)}`
          // );
          // if we found a token but its expired
          if (time >= token.expires_in) {
            // and delete that token that existed.
            // generate a new token
            generateSpotifyToken().then(data => {
              addTokenToDB(data).then(() => {
                resolve(data.access_token);
              });
              removeTokenFromDB(tokenRef.id);
            });
          } else {
            resolve(token.access_token);
          }
        } else {
          generateSpotifyToken().then(data => {
            addTokenToDB(data).then(() => {
              resolve(data.access_token);
            });
          });
        }
      });
  });
};
