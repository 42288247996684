import * as React from "react";
import { db } from "../../secondary/firebase";
import { ArtistFestivalTile } from "../ArtistTile/index";
import FstvlDivider from "../FstvlDivider";
import { useDocument } from "react-firebase-hooks/firestore";
import { Row, Col, Typography, Button, Spin } from "antd";
import Interactable from "react-interactable/noNative";
import { userContext } from "../../utilities/user-context";
import { artistValue } from "../../utilities/artists";

const FestivalDetailArtistBar = props => {
  const { artists, id } = props;
  const { Title } = Typography;
  const topArtists = [...artists].slice(0, 6);
  const artistBarRef = React.createRef();
  const { user } = React.useContext(userContext);
  const [leftBound, setLeftBound] = React.useState(0);
  const [rightBound, setRightBound] = React.useState(0);
  const [favedArtists, favedArtistsLoading] = useDocument(
    db
      .collection("favedArtists")
      .doc(user.uid)
      .collection(id.toString())
  );

  React.useEffect(() => {
    if (!artistBarRef.current) return;
    const bounds = artistBarRef.current.offsetWidth;
    setLeftBound(-Math.abs(bounds - 100));
    setRightBound(50);
  }, [topArtists, artistBarRef]);

  if (favedArtistsLoading) {
    return (
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          padding: "3rem",
          justifyContent: "center",
          display: "flex"
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="container mx-auto">
      <Row gutter={16}>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
          <div className="my-24 overflow-hidden">
            <Title className="text-black flush-top" level={3}>
              Artists at {props.name}
            </Title>
            <FstvlDivider color="blue" size="" />
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <div className="w-full pb-12">
          <Interactable.View
            horizontalOnly={true}
            frictionAreas={[{ damping: 0.95, influenceArea: { top: 0 } }]}
            boundaries={{ left: leftBound, right: rightBound, bounce: 0.1 }}
          >
            <div ref={artistBarRef} className="flex flex-row w-full">
              {topArtists.map(item => {
                let artistInterest = artistValue(favedArtists.docs, item.id);

                return (
                  <ArtistFestivalTile
                    key={item.id}
                    artist={item}
                    interest={artistInterest}
                  />
                );
              })}
            </div>
          </Interactable.View>
        </div>
      </Row>
      <Row gutter={16}>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
          <div className="overflow-hidden w-full flex justify-center items-center">
            <Button
              className="flex justify-center items-center"
              size=""
              onClick={props.drawer.bind(this)}
              type="secondary"
            >
              View All Artists
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FestivalDetailArtistBar;
