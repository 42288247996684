import firebase from "@firebase/app";
import "@firebase/firestore";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBfyJLHMfpgNv8IK_JPLDKrRbcW4O8sRl0",
  authDomain: "fstvl-app.firebaseapp.com",
  databaseURL: "https://fstvl-app.firebaseio.com",
  projectId: "fstvl-app",
  storageBucket: "fstvl-app.appspot.com",
  messagingSenderId: "651452060689",
  appId: "1:651452060689:web:562cf4925c25e799310db0"
});

const db = firebaseApp.firestore();

export { db, firebaseApp };
