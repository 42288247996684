import * as React from "react";
import { Row, Col, Typography } from "antd";
import ImageFadeIn from "react-image-fade-in";

const ArtistSetTimeTile = props => {
  const { artist } = props;
  const { Title, Text } = Typography;

  return (
    <Row className="mb-12 flex-row flex w-full items-center">
      <Col xs={{ span: 4, offset: 1 }} lg={{ span: 4, offset: 1 }}>
        <div className="relative aspect-ratio-square rounded-full overflow-hidden bg-grey-light-lighten-4">
          {artist.image ? (
            <ImageFadeIn
              className="fill-parent inset-0 absolute bg-cover"
              src={artist.image}
              loadAsBackgroundImage={true}
              opacityTransition={0.25}
            />
          ) : (
            <></>
          )}
        </div>
      </Col>
      <Col xs={{ span: 12, offset: 1 }} lg={{ span: 11, offset: 2 }}>
        <Title className="text-black flush" level={4}>
          {artist.name}
        </Title>
        <Text className="flush-bottom body-1">
          {artist.moment.start} - {artist.moment.end} at {artist.times.stage}
        </Text>
      </Col>
    </Row>
  );
};

export default ArtistSetTimeTile;
