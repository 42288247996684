// index.js
import React from "react";
import { render } from "react-dom";
import { StoreProvider } from "./store";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import "../css/index.css";
import "../css/index.less";

render(
  <StoreProvider>
    <App />
  </StoreProvider>,
  document.getElementById("root")
);

// serviceWorker.unregister();
