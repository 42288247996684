import React from "react";
import { Skeleton, Space, Row, Col } from "antd";

const LoadingFestivalCard = () => {
  const loading = true;

  return (
    <Row gutter={16}>
      <div className="festival-card rounded-sm border-red border w-full flex flex-row mb-24 h-200">
        <Col span="6">
          <div className="absolute inset-0 bg-blue-light">
            <div className="bg-grey-dark-lighten-4 inset-0 absolute"></div>
          </div>
        </Col>
        <Col
          span="16"
          offset="1"
          className="festival-card__content-wrap flex flex-col"
        >
          <Space className="w-full">
            <Skeleton active paragraph={{ width: 200, rows: 2 }} />
          </Space>
          <Space>
            <Skeleton.Button active={loading} size="large" shape="default" />
            <Skeleton.Button active={loading} size="large" shape="default" />
          </Space>
        </Col>
      </div>
    </Row>
  );
};

export default LoadingFestivalCard;
