import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Typography, Button } from "antd";
import { Link } from "wouter";
import FstvlDivider from "../FstvlDivider";
import { storeFestival, removeFestival } from "../../actions/user-festivals";
import LoadingFestivalCard from "../LoadingFestivalCard";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { userContext } from "../../utilities/user-context";
import { db } from "../../secondary/firebase";
import {
  ClockCircleOutlined,
  EnvironmentFilled,
  HeartOutlined,
  HeartFilled,
  StarFilled
} from "@ant-design/icons";

const { Title, Text } = Typography;

const FestivalCard = props => {
  const { id, isFaved, fid } = props;
  const { user } = useContext(userContext);
  const [festival, loading] = useDocumentData(
    db.collection("festivals").doc(id)
  );
  const [faveId, setFavId] = useState(fid);

  function toggleFestivalStore() {
    if (id && isFaved) {
      setFavId("");
      removeFestival(user, faveId);
    } else {
      storeFestival(user, id).then(data => {
        setFavId(data.id);
      });
    }
  }

  useEffect(() => {
    setFavId(fid);
  }, [fid]);

  if (!festival || loading) {
    return <LoadingFestivalCard />;
  }

  return (
    <Row id={id} gutter={16}>
      <div className="relative festival-card w-full h-200 flex flex-row mb-24 items-center justify-center py-24">
        <Col span="6">
          <div className="w-full max-h-150">
            <div className="aspect-ratio-5/7 bg-grey-light-darken-1"></div>
          </div>
        </Col>

        <Col
          span="17"
          className="festival-card__content-wrap flex flex-col items-center justify-start h-full"
        >
          <div className="self-start w-full">
            <Title level={4}>{festival.seriesName || festival.name}</Title>
            <FstvlDivider color="blue" />
          </div>

          <div className="festival-card__content w-full flex flex-grow items-center">
            <ul className="py-8 my-8 mb-0 w-full">
              <li className="flex flex-row items-center w-full mb-6">
                <EnvironmentFilled className="text-blue-light mr-6" />
                <Text className="body-2 text-black" type="secondary">
                  {festival.location.city}
                </Text>
              </li>
              <li className="flex flex-row items-center w-full mb-6">
                <StarFilled className="text-pink mr-6" />
                <Text className="body-2 text-black" type="secondary">
                  {festival.artists.length || 0} artist are performing
                </Text>
              </li>
            </ul>
          </div>

          <div className="festival-card__button-container flex self-end w-full">
            <Link to={`festival/${id}`}>
              <Button
                className="flex justify-center items-center mr-10"
                size=""
                type="primary"
                icon={<ClockCircleOutlined />}
              >
                Lineup
              </Button>
            </Link>
            <Button
              className="flex justify-center items-center"
              onClick={toggleFestivalStore}
              size=""
              type="secondary"
              icon={isFaved ? <HeartFilled /> : <HeartOutlined />}
            ></Button>
          </div>
        </Col>
      </div>
    </Row>
  );
};

export default FestivalCard;
