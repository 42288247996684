import "firebase/auth";
import { useContext } from "react";
import { userContext } from "../utilities/user-context";
import { getUserFields, createEntry, deleteEntry } from "../actions/db";

// const provider = new firebase.auth.GoogleAuthProvider();

export const useSession = () => {
  const { user } = useContext(userContext);
  return user;
};

export const storeFestival = async (user, id) => {
  try {
    // const result = await firebase.auth().signInWithPopup(provider);
    const faved = await createEntry({
      createdBy: getUserFields(user),
      uid: user.uid,
      fid: id
    });

    return faved;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const removeFestival = async (user, id) => {
  try {
    await deleteEntry(id);

    return id;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
