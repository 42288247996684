import React, { useContext, useEffect, useState } from "react";
import { Typography } from "antd";
import FestivalCard from "./FestivalCard/FestivalCard";
import FstvlDivider from "./FstvlDivider";
import { userContext } from "../utilities/user-context";
import { db } from "../secondary/firebase";

const ALlFestivals = props => {
  const { Title } = Typography;
  const { festivals, favedFestivals } = props;
  const { user } = useContext(userContext);
  const [favedFestivalsData, setFavedFestivals] = useState(favedFestivals);

  useEffect(() => {
    setFavedFestivals(favedFestivals);
  }, [favedFestivalsData, favedFestivals]);

  return (
    <div>
      <div className="my-24 overflow-hidden">
        <Title className="text-black flush-top" level={4}>
          All Upcoming Festivals
        </Title>
        <FstvlDivider size="large" />
      </div>

      {festivals.docs.map(item => {
        const favedFstvl = favedFestivals.find(fstvl => {
          return item.id == fstvl.data().fid;
        });
        const isFaved = favedFstvl ? true : false;
        const id = item.data().id.toString();

        return (
          <FestivalCard
            userFestCard={false}
            key={id}
            isFaved={isFaved}
            uid={user.uid}
            fid={favedFstvl ? favedFstvl.id : undefined}
            id={id}
          />
        );
      })}
    </div>
  );
};

export default ALlFestivals;
