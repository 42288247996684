import * as React from "react";
import { Row, Col, Typography } from "antd";
import ImageFadeIn from "react-image-fade-in";
import { storeArtist, removeArtist } from "../../actions/user-artists";
import { userContext } from "../../utilities/user-context";
import MultiToggle from "../../components/MultiToggle";
import {
  HeartFilled,
  StopOutlined,
  CheckCircleOutlined
} from "@ant-design/icons";

const { Text } = Typography;

const ArtistFestivalDrawerTile = props => {
  const { artist, id, interests } = props;
  const { user } = React.useContext(userContext);
  const [artistValue, setArtistValue] = React.useState(interests);
  const prevArtistValue = usePreviousValue(artistValue);
  const { images } = artist;
  const selectOptions = [
    {
      displayName: <StopOutlined />,
      value: 1
    },
    {
      displayName: <CheckCircleOutlined />,
      value: 2
    },
    {
      displayName: <HeartFilled />,
      value: 3
    }
  ];

  function usePreviousValue(value) {
    const ref = React.useRef();

    React.useEffect(() => {
      ref.current = value;
    });

    return ref.current;
  }

  function onGroupSizeSelect(value) {
    setArtistValue(value);
  }

  React.useEffect(() => {
    if (!prevArtistValue) return;

    function handleAddArtist() {
      const obj = { ...artist, fid: id, value: artistValue };

      storeArtist(user, obj);
    }

    function handleRemoveArtist() {
      const obj = { ...artist, fid: id };
      removeArtist(user, obj);
    }

    if (prevArtistValue !== artistValue) {
      artistValue >= 2 ? handleAddArtist() : handleRemoveArtist();
    }
  }, [artistValue, artist, id, user, prevArtistValue]);

  return (
    <Row className="mb-12 flex-row flex w-full items-center">
      <Col xs={{ span: 4 }} lg={{ span: 4 }}>
        <div className="relative aspect-ratio-square rounded-full overflow-hidden bg-grey-light-lighten-4">
          {images ? (
            <ImageFadeIn
              className="fill-parent inset-0 absolute bg-cover"
              src={images[0].url}
              loadAsBackgroundImage={true}
              opacityTransition={0.25}
            />
          ) : (
            <></>
          )}
        </div>
      </Col>
      <Col xs={{ span: 12, offset: 1 }} lg={{ span: 11, offset: 2 }}>
        <Text className="body-2 text-black" type="secondary">
          {artist.displayName}
        </Text>
      </Col>
      <Col xs={{ span: 7 }} lg={{ span: 7 }}>
        <MultiToggle
          options={selectOptions}
          selectedOption={artistValue ? artistValue : false}
          onSelectOption={onGroupSizeSelect.bind(this)}
        />
      </Col>
    </Row>
  );
};

export default ArtistFestivalDrawerTile;
