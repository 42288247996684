import React, { useState, useEffect } from "react";
import { animated, useTransition, config } from "react-spring";
import HeaderHeroImage from "../containers/HeaderHeroImage";

const HeaderHero = () => {
  const slides = [
    {
      id: 0,
      url:
        "photo-1582711012124-a56cf82307a0?ixlib=rb-1.2.1&auto=format&fit=crop&auto=format&w=1200&q=80"
    },
    {
      id: 1,
      url:
        "photo-1497911270199-1c552ee64aa4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&w=1534&q=80"
    },
    {
      id: 2,
      url:
        "photo-1493676304819-0d7a8d026dcf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1534&q=80"
    },
    {
      id: 3,
      url:
        "photo-1497911174120-042e550e7e0a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2238&q=80"
    }
  ];
  // useEffect(() => {
  //   return void setInterval(() => {
  //     set(state => (state + 1) % 4);
  //   }, duration);
  // }, []);

  const duration = 15000;
  const [index, set] = useState(0);
  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 0.75 },
    leave: { opacity: 0 },
    config: config.molasses
  });
  useEffect(
    () => void setInterval(() => set(state => (state + 1) % 4), duration),
    []
  );

  return (
    <div className="px-16 bg-grey-dark-lighten-1 h-200 bg-cover bg-no-repeat relative overflow-hidden">
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} style={{ ...props }}>
          <HeaderHeroImage
            url={`url(https://images.unsplash.com/${item.url}&auto=format&fit=crop)`}
            duration={duration}
          />
        </animated.div>
      ))}
    </div>
  );
};

export default HeaderHero;
