export const artistValue = (list, id) => {
  if (!list.some) return false;
  const fav = list.find(a => a.data().id === id);

  if (fav) {
    return fav.data().value;
  } else {
    return 1;
  }
};
