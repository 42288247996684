import * as React from "react";
import { Row, Col, Typography } from "antd";

const FestivalDetailDescription = props => {
  const { id } = props;
  const { Title, Text, Paragraph } = Typography;

  console.log(id);

  const article =
    "Nunc iaculis ad commodo laoreet aliquet ornare pretium tortor congue, erat non nisl augue magna faucibus convallis sapien, ultrices pellentesque urna morbi massa curabitur lorem diam. Cursus tortor eleifend consectetur himenaeos ante risus ligula vitae sagittis, montes congue dictumst molestie vehicula iaculis tincidunt justo dapibus netus, ornare viverra vivamus hac orci senectus vestibulum consequat lorem, eros taciti semper suscipit maecenas ultricies hendrerit pharetra. Et pharetra est orci felis bibendum sit viverra egestas, eget nisi luctus hendrerit gravida blandit inceptos amet dignissim, sem laoreet ultrices litora nisl integer vitae. Nisl a magna aliquam suspendisse commodo aptent fusce quis montes ridiculus ex, finibus vel risus libero sem eros turpis dictum posuere massa.";

  return (
    <div className="my-24">
      <Row gutter={16} className="flex flex-row w-full justify-center mb-24">
        <Col
          className="flex flex-col justify-center items-center"
          xs={{ span: 4, offset: 1 }}
        >
          <div className="w-full aspect-ratio-square border-grey-dark border rounded-sm"></div>
        </Col>
        <Col xs={{ span: 17, offset: 1 }}>
          <Title className="text-black flush" level={4}>
            Number of Stages
          </Title>
          <Text className="body-2 text-black" type="secondary">
            Conubia eros luctus ut morbi nibh phasellus sollicitudin justo dis.
          </Text>
        </Col>
      </Row>
      <Row gutter={16} className="flex flex-row w-full justify-center mb-16">
        <Col
          className="flex flex-col justify-center items-center"
          xs={{ span: 4, offset: 1 }}
        >
          <div className="w-full aspect-ratio-square border-grey-dark border rounded-sm"></div>
        </Col>
        <Col xs={{ span: 17, offset: 1 }}>
          <Title className="text-black flush" level={4}>
            Age Restriction
          </Title>
          <Text className="body-2 text-black" type="secondary">
            Conubia eros luctus ut morbi nibh phasellus sollicitudin justo dis.
          </Text>
        </Col>
      </Row>
      <Row gutter={16} className="flex flex-row w-full">
        <Col xs={{ span: 21, offset: 1 }}>
          <Paragraph
            className="body-1 flush"
            ellipsis={{
              rows: 3,
              expandable: true,
              symbol: "Read More"
            }}
          >
            {article}
          </Paragraph>
        </Col>
      </Row>
    </div>
  );
};

export default FestivalDetailDescription;
