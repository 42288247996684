import * as React from "react";
import { Col, Typography } from "antd";
import ImageFadeIn from "react-image-fade-in";

const { Text } = Typography;

const ArtistFestivalTile = props => {
  const { artist } = props;
  // const data = useArtistInfo(artist.displayName);
  // const [ref, { width }] = useMeasure();

  // if (data.loading || !data.response) {
  //   return (
  //     <Col
  //       className="max-w-100"
  //       xs={{ span: 6, offset: 1 }}
  //       lg={{ span: 6, offset: 1 }}
  //     >
  //       <div ref={ref} className="justify-center text-center flex flex-col">
  //         <div className="aspect-ratio-square rounded-full overflow-hidden">
  //           <Skeleton.Avatar
  //             clssName="w-full"
  //             size={width}
  //             active
  //             shape="circle"
  //           ></Skeleton.Avatar>
  //         </div>
  //       </div>
  //     </Col>
  //   );
  // }

  return (
    <Col
      className="max-w-100"
      xs={{ span: 6, offset: 1 }}
      lg={{ span: 6, offset: 1 }}
    >
      <div className="justify-center text-center flex flex-col">
        <div className="relative aspect-ratio-square rounded-full overflow-hidden bg-grey-light-lighten-4">
          {artist.images ? (
            <ImageFadeIn
              className="fill-parent inset-0 absolute bg-cover"
              src={artist.images[0].url}
              loadAsBackgroundImage={true}
              opacityTransition={0.25}
            />
          ) : (
            <></>
          )}
        </div>
        <Text
          className="body-2 text-black mt-8 leading-snug truncate"
          type="secondary"
        >
          {artist.displayName}
        </Text>
      </div>
    </Col>
  );
};

export default ArtistFestivalTile;
