import React from "react";
import { userContext } from "../utilities/user-context";
import { Spin } from "antd";
import { useDocument } from "react-firebase-hooks/firestore";
import { db } from "../secondary/firebase";
import { FestivalSetDay } from "./FestivalSetTimes";
import ErrorBoundary from "./ErrorBoundary";
import Moment from "moment";

const FestivalLineup = props => {
  const { id } = props;
  const { user } = React.useContext(userContext);
  const [festivalValue, festivalLoading] = useDocument(
    db.collection("festivals").doc(id)
  );
  const [valuedPerformers, setValuedPerformers] = React.useState({});
  const [performers, setPerformers] = React.useState([]);
  const [favedArtists, setFavedArtists] = React.useState([]);
  const [favedArtistsDoc, favedArtistsLoading] = useDocument(
    db
      .collection("favedArtists")
      .doc(user.uid)
      .collection(id.toString())
  );

  // const [favedFestivalList, loadingFaved] = useCollection(
  //   db.collection("favedFestivals")
  // )

  React.useEffect(() => {
    if (!festivalValue) return;

    const data = festivalValue.data();
    const performerArr = [];

    // if we have an length of artists we should loop through these
    // and generate a more simple and smaller object which contains
    // only the artist name and times with stage.
    if (data.artists.length) {
      for (let performer of data.artists) {
        let obj = {
          name: performer.displayName,
          id: performer.id,
          times: performer.times || null,
          image: performer.images ? performer.images[0].url : null,
          value: 0
        };

        performerArr.push(obj);
      }

      setPerformers(performerArr || []);
    }
  }, [festivalValue]);

  React.useEffect(() => {
    if (!favedArtistsDoc) return;
    const arr = [];

    // we need to get the data from firebase and create a shallow object
    // for each artists with just the id and value.
    for (let artist of favedArtistsDoc.docs) {
      const artistData = artist.data();
      const obj = {
        id: artistData.id,
        value: artistData.value
      };

      arr.push(obj);
    }

    setFavedArtists(arr);
  }, [favedArtistsDoc]);

  React.useEffect(() => {
    // if we don't have any faved artists or artists performing at this event
    // don't render
    if (!performers.length || !favedArtists.length) return;

    // we need to now map the artists from the faved list and filter out
    // only artists which have been faved.
    const dates = {};

    // loop through and find the artists if theyre faved and genertae a new
    // object with values and times
    performers.map(item => {
      const faved = favedArtists.find(artist => item.id === artist.id);

      // TODO (08-01-2020):
      // Need to make the dates map to the festival start date and festival
      // end date. Not the calender date
      // ====
      // We now need to split up each artist into their retrospective dates.
      if (faved && item.times) {
        // we will get a string like '01-01-2020 15:00', so lets split that and
        // create an object containing an array of artists.
        const start = item.times.start.split(" ")[0];

        const timeObj = {
          ...faved,
          moment: {
            start: Moment(item.times.start)
              .format("LT")
              .split(" ")[0],
            end: Moment(item.times.end)
              .format("LT")
              .split(" ")[0]
          }
        };

        const obj = { ...item, ...timeObj };

        // if the date not have an array lets make one.
        if (!dates[start]) {
          dates[start] = [];
        } else {
          dates[start].push(obj);
        }
      }
    });

    // Now that we have them sorted by their values we need to sort the
    // favs (val 3) by the order of their appearance time, and then the
    // "down to sees (val: 2)" by their appearance time.
    for (let date in dates) {
      dates[date] = dates[date].sort((a, b) => {
        const aStart = a.times.start.split(" ")[1].replace(":", ".") * 1;
        const bStart = b.times.start.split(" ")[1].replace(":", ".") * 1;
        return aStart - bStart;
      });
    }

    setValuedPerformers(dates);
  }, [performers, favedArtists]);

  if (festivalLoading || favedArtistsLoading) {
    return (
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          padding: "3rem",
          justifyContent: "center",
          display: "flex"
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (Object.entries(valuedPerformers).length === 0) {
    return (
      <div>
        <h1>Set Times Yet To Be Released.</h1>
      </div>
    );
  }

  console.log(valuedPerformers);

  return (
    <div className="container">
      {Object.keys(valuedPerformers).map((performers, index) => {
        return (
          <FestivalSetDay
            key={index}
            day={index + 1}
            performers={valuedPerformers[performers]}
          />
        );
      })}
    </div>
  );
};

export default FestivalLineup;

// export default function FestivalDetailsWithErrorBoundary(props) {
//   return (
//     <ErrorBoundary>
//       <FestivalLineup {...props}></FestivalLineup>
//     </ErrorBoundary>
//   );
// }
