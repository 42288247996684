import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { db } from "../secondary/firebase";

export function getUserFields(user) {
  return {
    uid: user.uid,
    displayName: user.displayName,
    email: user.email,
    photoURL: user.photoURL
  };
}

export const createEntry = obj => {
  return db
    .collection("favedFestivals")
    .add({
      ...obj,
      updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
    })
    .then(docRef => {
      return docRef;
    });
};

export const deleteEntry = id => {
  return db
    .collection("favedFestivals")
    .doc(id)
    .delete();
};

export const deleteArtistEntry = obj => {
  // get the user id from the object and find if a set with that UID
  // exists.
  db.collection("favedArtists")
    .doc(obj.uid)
    .get()
    .then(doc => {
      if (doc.exists) {
        checkFestivalDocs(doc, obj);
      } else {
        console.log("can't find it so were removing nothing");
      }
    });
};

export const createArtistEntry = obj => {
  // get the user id from the object and find if a set with that UID
  // exists.
  db.collection("favedArtists")
    .doc(obj.uid)
    .get()
    .then(doc => {
      // we didnt even find the first collection, so we should add it.
      if (doc.exists) {
        createArtistCollection(doc, obj);
      } else {
        createArtistFestivalCollections(obj);
      }
    });
};

const checkFestivalDocs = async (doc, obj) => {
  const dbRef = db;

  const ref = dbRef
    .collection("favedArtists")
    .doc(doc.id)
    .collection(obj.fid.toString())
    .doc(obj.data.id.toString());

  return ref.delete();
};

const createArtistFestivalCollections = async obj => {
  const collection = db.collection("favedArtists");
  const ref = collection.doc(obj.uid);
  const fid = obj.fid;
  const festivalObj = {};
  festivalObj[fid] = {};
  festivalObj[fid][obj.data.id] = { ...obj.createdBy, ...obj.data };

  await ref.set({});

  const artistCollectionRef = collection
    .doc(obj.uid)
    .collection(fid.toString());

  await artistCollectionRef
    .doc(obj.data.id.toString())
    .set(festivalObj[fid][obj.data.id]);
};

const createArtistCollection = async (doc, obj) => {
  const dbRef = db;
  const ref = dbRef
    .collection("favedArtists")
    .doc(doc.id)
    .collection(obj.fid.toString())
    .doc(obj.id.toString());
  const festivalObj = { ...obj.createdBy, ...obj };

  await ref.set(festivalObj);
};
