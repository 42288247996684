import React from "react";
import { Link } from "@reach/router";

class EmptyFestivalCard extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div className="landing-view">
        <h1>No Festivals Found</h1>
        <Link to="/create">Create a Festival</Link>
      </div>
    );
  }
}

export default EmptyFestivalCard;
