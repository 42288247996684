import * as React from "react";
import ErrorBoundary from "../ErrorBoundary";
import { db } from "../../secondary/firebase";
import { useDocument } from "react-firebase-hooks/firestore";
import { Button } from "antd";
import { userContext } from "../../utilities/user-context";
import { Spin } from "antd";
import { artistValue } from "../../utilities/artists";
import { ArtistFestivalDrawerTile } from "../ArtistTile/index";

const FestivalLineup = props => {
  const { id } = props;
  const [festivalValue, festivalLoading] = useDocument(
    db.collection("festivals").doc(id)
  );
  const { user } = React.useContext(userContext);
  const [artists, setArtists] = React.useState([]);
  const [favedArtists, setFavedArtists] = React.useState([]);
  const [favedArtistsDoc, favedArtistsLoading] = useDocument(
    db
      .collection("favedArtists")
      .doc(user.uid)
      .collection(id.toString())
  );

  React.useEffect(() => {
    if (!favedArtistsLoading) {
      setArtists([...festivalValue.data().artists]);
    }
  }, [festivalValue, favedArtistsLoading]);

  React.useEffect(() => {
    if (!favedArtistsDoc) return;

    setFavedArtists(favedArtistsDoc.docs);
  }, [favedArtistsDoc]);

  if (festivalLoading && favedArtistsLoading) {
    return (
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          padding: "3rem",
          justifyContent: "center",
          display: "flex"
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      {artists.map(item => {
        let artistInterest = artistValue(favedArtists, item.id);

        return (
          <ArtistFestivalDrawerTile
            id={id}
            key={item.id}
            artist={item}
            interests={artistInterest}
          ></ArtistFestivalDrawerTile>
        );
      })}
    </div>
  );
};

export default function FestivalDetailsWithErrorBoundary(props) {
  return (
    <ErrorBoundary>
      <FestivalLineup {...props}></FestivalLineup>
    </ErrorBoundary>
  );
}
