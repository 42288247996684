import * as React from "react";
import { Button, Layout, Typography, Row, Col } from "antd";
import HeaderHero from "../HeaderHero";
import {
  EnvironmentFilled,
  CalendarOutlined,
  HeartFilled,
  HeartOutlined
} from "@ant-design/icons";
import { storeFestival, removeFestival } from "../../actions/user-festivals";
import { userContext } from "../../utilities/user-context";
import Moment from "react-moment";

const { Title, Text } = Typography;

const FestivalDetailHeader = props => {
  const { img, id, faved, name, location, date } = props;
  const containerRef = React.createRef();
  const { user } = React.useContext(userContext);
  const [headerHeight, setHeaderHeight] = React.useState(-100);
  const [isFaved, setFaved] = React.useState(faved);

  function toggleFestivalStore() {
    if (id && isFaved) {
      setFaved(false);
      removeFestival(user, isFaved.id);
    } else {
      storeFestival(user, id).then(data => {
        setFaved(data);
      });
    }
  }

  React.useEffect(() => {
    setHeaderHeight(containerRef.current.offsetHeight);
  }, [name, containerRef]);

  return (
    <div ref={containerRef}>
      <HeaderHero img={img}></HeaderHero>
      <Row gutter={16}>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
          <Layout
            style={{ marginTop: -Math.abs(headerHeight / 3) }}
            className="mx-auto px-24 pt-24 layout-inner text-center rounded overflow-hidden"
          >
            <div className="bg-white">
              <Title className="flush-bottom" level={2}>
                {name}
              </Title>
              <ul className=" my-6">
                <li className="w-full flex flex-row justify-center items-center mb-8">
                  <EnvironmentFilled className="text-blue-light mr-6" />
                  <Text className="body-2 text-black" type="secondary">
                    {location}
                  </Text>
                </li>
                <li className="w-full flex flex-row justify-center items-center">
                  <CalendarOutlined className="text-grey-light-darken-2 mr-6" />
                  <Text className="body-2 text-black" type="secondary">
                    <Moment format="MMM D, YYYY">{date.start.date}</Moment>
                    &nbsp;–&nbsp;
                    <Moment format="MMM D, YYYY">{date.end.date}</Moment>
                  </Text>
                </li>
              </ul>

              <div className="mt-24 flex w-full justify-center">
                <Button
                  className="flex justify-center items-center mr-10"
                  size=""
                  onClick={toggleFestivalStore}
                  type="primary"
                  icon={isFaved ? <HeartFilled /> : <HeartOutlined />}
                >
                  {isFaved ? `Remove From Favorites` : `Add To Favorites`}
                </Button>
              </div>
            </div>
          </Layout>
        </Col>
      </Row>
    </div>
  );
};

export default FestivalDetailHeader;
