import "firebase/auth";
import { useContext } from "react";
import { userContext } from "../utilities/user-context";
import {
  getUserFields,
  createArtistEntry,
  deleteArtistEntry
} from "../actions/db";

// const provider = new firebase.auth.GoogleAuthProvider();

export const useSession = () => {
  const { user } = useContext(userContext);
  return user;
};

export const storeArtist = async (user, obj) => {
  try {
    // const result = await firebase.auth().signInWithPopup(provider);
    const faved = await createArtistEntry({
      createdBy: getUserFields(user),
      uid: user.uid,
      id: obj.id,
      fid: obj.fid || null,
      value: obj.value
    });

    return faved;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const removeArtist = async (user, obj) => {
  try {
    await deleteArtistEntry({
      uid: user.uid,
      fid: obj.fid || null,
      data: obj
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};
