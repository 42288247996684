import * as React from "react";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import { public_token } from "../../../app/config/mapbox";
import FstvlDivider from "../FstvlDivider";
import { Typography, Spin, Row, Col } from "antd";
import { EnvironmentFilled } from "@ant-design/icons";

const { Title, Text } = Typography;

const FestivalDetailMap = props => {
  const { location, venue } = props;
  const [mapboxState, setMapBoxState] = React.useState({ lat: 0, lng: 0 });

  React.useEffect(() => {
    setMapBoxState({
      lng: location.lng,
      lat: location.lat,
      zoom: 12
    });
  }, [location]);

  if (!location) {
    return (
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          padding: "3rem",
          justifyContent: "center",
          display: "flex"
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  const Map = ReactMapboxGl({
    accessToken: public_token,
    interactive: false
  });

  return (
    <div className="container mx-auto">
      <Row gutter={16}>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
          <div className="mb-24 overflow-hidden">
            <Title className="text-black flush-top" level={3}>
              Venue and Location
            </Title>
            <FstvlDivider color="blue" size="" />
          </div>
        </Col>
      </Row>
      <Row className="mb-12">
        <Col xs={{ span: 22, offset: 1 }}>
          <div className="h-200 w-full">
            <Map
              className="h-full"
              center={[mapboxState.lng, mapboxState.lat]}
              zoom={[13]}
              style="mapbox://styles/cujojp/ckd8dmthg0mct1iobumgi5y5z"
            >
              <Marker coordinates={[mapboxState.lng, mapboxState.lat]}>
                <div className="w-45 rounded-full overflow-hidden aspect-ratio-square">
                  <div className="absolute inset-0 flex flex-col justify-center items-center">
                    <div className="absolute inset-0 bg-grey-dark-lighten-1 opacity-10 rounded-full overflow-hidden"></div>
                    <EnvironmentFilled className="text-pink text-2xl relative z-10" />
                  </div>
                </div>
              </Marker>
            </Map>
          </div>
        </Col>
      </Row>
      <Row className="mb-12">
        <Col xs={{ span: 22, offset: 1 }}>
          <Text className="flush-bottom body-1">
            {venue.displayName}, {location.city}
          </Text>
        </Col>
      </Row>
    </div>
  );
};

export default FestivalDetailMap;
