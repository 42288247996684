import React from "react";
import { Col, Row, Typography } from "antd";
import { ArtistSetTimeTile } from "../ArtistTile/index";

const FestivalSetDay = props => {
  const { day, performers } = props;
  const { Text } = Typography;

  console.log(performers);

  return (
    <div className="container mx-auto details">
      <Row className="mb-12 flex-row flex w-full items-center">
        <Col xs={{ span: 20, offset: 1 }}>
          <Text>Day {day}</Text>
        </Col>
      </Row>
      {performers.map(artist => {
        return <ArtistSetTimeTile key={artist.id} artist={artist} />;
      })}
    </div>
  );
};

export default FestivalSetDay;
